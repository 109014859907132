<template>
  <div class="home">
    <componet :is="componentName"></componet>
  </div>
</template>

<script>
import defaultDashboard from './component/defaultDashboard/index.vue'
import fooyoDashboard from './component/fooyoDashboard/index.vue'

export default {
  name: 'HomeIndex',
  data () {
    return {
      app_name: process.env.VUE_APP_NAME,
      componentName: 'defaultDashboard'
    }
  },
  components: {
    defaultDashboard,
    fooyoDashboard
  },
  created () {
    console.log(this.app_name)
    if (this.app_name === 'fooyo') {
      this.componentName = 'fooyoDashboard'
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
  @import "index";
</style>
