<template>
  <div class="home">
    <el-card>
      <div slot="header" class="clearfix">
        <span>{{$t('dashboard.todayMission')}}</span>
      </div>
      <el-row :gutter="20">
        <el-col :span="16">
          <div>{{$t('dashboard.userOrders')}}</div>
          <div class="today-block">
            <el-row>
              <el-col :span="6">
                <div class="block-item" @click="toOrder('ordersManagerUserOrdersList', 'to_confirm')">
                  <div class="item-number">{{basicInfo.pending_confirmation_user_orders_count || 0 }}</div>
                  <div>{{$t('dashboard.unconfirm')}}</div>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="block-item" @click="toOrder('ordersManagerUserOrdersList', 'pending_dispatch_today')">
                  <div class="item-number">{{basicInfo.pending_dispatch_user_orders_count || 0 }}</div>
                  <div>{{$t('dashboard.undelivery')}}</div>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="block-item" @click="toOrder('ordersManagerUserOrdersList', 'pending_payment')">
                  <div class="item-number">{{basicInfo.today_paid_user_orders_count || 0}}</div>
                  <div>{{$t('dashboard.paidToday')}}</div>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="block-item" @click="toOrder('ordersManagerUserOrdersList', 'fulfilled')">
                  <div class="item-number">{{basicInfo.today_fulfilled_user_orders_count || 0}}</div>
                  <div>{{$t('dashboard.fulfilledToday')}}</div>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :span="8">
          <div>{{$t('dashboard.afterbuy')}}</div>
          <div class="today-block">
            <el-row>
              <el-col :span="12">
                <div class="block-item" @click="toOrder('ordersManagerAfterSalesOrdersList', 'pending_admin')">
                  <div class="item-number">{{basicInfo.pending_after_sales_orders_count || 0}}</div>
                  <div>{{$t('dashboard.unComfirm2')}}</div>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="block-item" @click="toOrder('ordersManagerAfterSalesOrdersList', 'refunding')">
                  <div class="item-number">{{basicInfo.refunding_after_sales_orders_count || 0}}</div>
                  <div>{{$t('dashboard.refunding')}}</div>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :span="16" style="margin-top: 20px" v-if="basicInfo.pending_delivery_orders || basicInfo.not_meet_moq_delivery_orders_count || basicInfo.cancelling_delivery_orders_count">
          <div>{{$t('menu.orderDeliver')}}</div>
          <div class="today-block">
            <el-row>
              <el-col :span="4">
                <div class="block-item" @click="toOrder('ordersManagerDeliveryOrdersList', 'pending_confirmation')">
                  <div class="item-number">{{basicInfo.pending_delivery_orders}}</div>
                  <div>{{$t('dashboard.unconfirm2')}}</div>
                </div>
              </el-col>
              <el-col :span="4">
                <div class="block-item" @click="toOrder('ordersManagerDeliveryOrdersList', 'pending_host')">
                  <div class="item-number">{{basicInfo.not_meet_moq_delivery_orders_count}}</div>
                  <div>{{$t('dashboard.unMOQ')}}</div>
                </div>
              </el-col>
              <el-col :span="4">
                <div class="block-item" @click="toOrder('ordersManagerDeliveryOrdersList', 'cancelling')">
                  <div class="item-number">{{basicInfo.cancelling_delivery_orders_count}}</div>
                  <div>{{$t('dashboard.cancling')}}</div>
                </div>
              </el-col>
              <el-col :span="4">
                <div class="block-item" @click="toOrder('ordersManagerDeliveryOrdersList', 'delivering')">
                  <div class="item-number">{{basicInfo.today_delivery_orders_count}}</div>
                  <div>{{$t('dashboard.toDeliveryToday')}}</div>
                </div>
              </el-col>
              <el-col :span="4">
                <div class="block-item">
                  <div class="item-number">{{basicInfo.today_delivery_orders_sales && '$' + basicInfo.today_delivery_orders_sales/100 || 0}}</div>
                  <div>{{$t('dashboard.salesToday')}}</div>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :span="8" style="margin-top: 20px">
          <div>{{$t('dashboard.groupbuyAction')}}</div>
          <div class="today-block">
            <el-row>
              <el-col :span="12">
                <div class="block-item" @click="toOrder('buddyManagerApplyList', 'pending')">
                  <div class="item-number">{{basicInfo.pending_host_applications_count || 0}}</div>
                  <div>{{$t('dashboard.pendingHostApplications')}}</div>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="block-item" @click="toOrder('buddyManagerWithdrawalApplicationList', 'pending')">
                  <div class="item-number">{{basicInfo.pending_host_cashback_withdraw_count || 0}}</div>
                  <div>{{$t('dashboard.pendingCashbackWithdrawal')}}</div>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </el-card>
    <el-card style="margin-top: 20px">
      <div slot="header" class="clearfix">
        <span>{{$t('dashboard.totalInfo')}}</span>
<!--        <p class="add-btn">+</p>-->
        <span style="float: right;">
          <el-select v-model="dateValue" @change="changeMonth" :placeholder="$t('placeholder.defaultSelect')">
            <el-option
              v-for="item in dateList"
              :key="item.text"
              :label="item.text"
              :value="item.value">
            </el-option>
          </el-select>
        </span>
      </div>
      <div>
        <span>{{getLaDate(dateValue)}}{{$t('dashboard.open')}}</span>
<!--        <span class="more">{{$t('dashboard.more')}}</span>-->
      </div>
      <el-row :gutter="20">
        <el-col :span="12">
          <div class="total-block">
            <el-row>
              <el-col :span="8">
                <div class="total-content">
                  <p class="number">{{moneyFormat(chartInfo.total_revenue) | numeral('0,0.00')}}</p>
                  <p class="title">{{$t('dashboard.realSell')}}($)</p>
                  <p class="note">{{$t('dashboard.get')}}:{{moneyFormat(chartInfo.total_revenue_before_refund) | numeral('0,0.00')}}</p>
                  <p class="note">{{$t('dashboard.refund')}}:{{moneyFormat(chartInfo.total_refund) | numeral('0,0.00')}}</p>
                </div>
              </el-col>
              <el-col :span="16">
                <div class="total-right">
                  <p class="text">
                    <span>{{$t('dashboard.deliveryOrders')}}</span>
                    <span class="money">{{moneyFormat(chartInfo.total_home_delivery_revenue) | numeral('0,0.00')}}</span>
                  </p>
                  <el-progress class="" :percentage="formatPercent(chartInfo.total_home_delivery_revenue, chartInfo.total_revenue)" :show-text="false"></el-progress>
                  <p class="text">
                    <span>{{$t('dashboard.mallOrders')}}</span>
                    <span class="money">{{moneyFormat(chartInfo.total_self_pickup_revenue) | numeral('0,0.00')}}</span>
                  </p>
                  <el-progress class="" :percentage="formatPercent(chartInfo.total_self_pickup_revenue, chartInfo.total_revenue)" :show-text="false"></el-progress>
                  <p class="text">
                    <span>{{$t('dashboard.otherOrders')}}</span>
                    <span class="money">{{moneyFormat(chartInfo.total_host_pickup_revenue) | numeral('0,0.00')}}</span>
                  </p>
                  <el-progress :percentage="formatPercent(chartInfo.total_host_pickup_revenue, chartInfo.total_revenue)" :show-text="false"></el-progress>

                  <p class="text">
                    <span>{{$t('dashboard.ticketOrders')}}</span>
                    <span class="money">{{moneyFormat(chartInfo.total_ticket_revenue) | numeral('0,0.00')}}</span>
                  </p>
                  <el-progress :percentage="formatPercent(chartInfo.total_ticket_revenue, chartInfo.total_revenue)" :show-text="false"></el-progress>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="total-block">
            <el-row>
              <el-col :span="8">
                <div class="total-content">
                  <p class="number">{{chartInfo.total_orders_count || 0}}</p>
                  <p class="title">{{$t('dashboard.totalOrders')}}</p>
                </div>
              </el-col>
              <el-col :span="16">
                <div class="total-right">
                  <p class="text">
                    <span>{{$t('dashboard.deliveryOrders')}}</span>
                    <span class="money">{{chartInfo.total_home_delivery_orders_count || 0}}</span>
                  </p>
                  <el-progress class="" :percentage="formatPercent(chartInfo.total_home_delivery_orders_count, chartInfo.total_orders_count)" :show-text="false"></el-progress>
                  <p class="text">
                    <span>{{$t('dashboard.mallOrders')}}</span>
                    <span class="money">{{chartInfo.total_self_pickup_orders_count || 0}}</span>
                  </p>
                  <el-progress class="" :percentage="formatPercent(chartInfo.total_self_pickup_orders_count, chartInfo.total_orders_count)" :show-text="false"></el-progress>
                  <p class="text">
                    <span>{{$t('dashboard.otherOrders')}}</span>
                    <span class="money">{{chartInfo.total_host_pickup_orders_count || 0}}</span>
                  </p>
                  <el-progress :percentage="formatPercent(chartInfo.total_host_pickup_orders_count, chartInfo.total_orders_count)" :show-text="false"></el-progress>

                  <p class="text">
                    <span>{{$t('dashboard.ticketOrders')}}</span>
                    <span class="money">{{chartInfo.total_ticket_orders_count || 0}}</span>
                  </p>
                  <el-progress :percentage="formatPercent(chartInfo.total_ticket_orders_count || 0, chartInfo.total_orders_count)" :show-text="false"></el-progress>

                </div>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
<!--      <p class="chart-title">{{$t('dashboard.turnoverChart')}}</p>-->
      <el-row>
        <el-col :span="12">
          <div class="test" ref="test1"></div>
        </el-col>
        <el-col :span="12">
          <div class="test" ref="test2"></div>
        </el-col>
      </el-row>
<!--      <p class="chart-title">{{$t('dashboard.orderTitle')}}</p>-->
      <el-row >
        <el-col :span="12">
          <div class="test" ref="test3"></div>
        </el-col>
        <el-col :span="12">
          <div class="test" ref="test4"></div>
        </el-col>
      </el-row>
      <div class="margintop50">
        <span>{{$t('dashboard.product')}}</span>
      </div>
      <div class="product-chart">
        <div class="intro margintop20">
          <span>{{$t('dashboard.paiming')}}</span>
          <span class="sell-text">{{$t('dashboard.selling')}}</span>
        </div>
        <div class="progress-item margintop20" v-for="(item, index) in chartInfo.top_shop_items" :key="index">
          <div class="progress-info marginBottom8">
            <p>{{item.name}}</p>
            <p><span class="num-info">{{item.value}}</span> {{$tc('dashboard.ge', item.value)}}</p>
          </div>
          <el-progress :percentage="formatPercent(item.value, chartInfo.top_shop_items[0].value)" :show-text="false"></el-progress>
        </div>
      </div>

      <div class="margintop50">
        <span>{{$t('dashboard.user')}}</span>
<!--        <span class="more">{{$t('dashboard.more')}}</span>-->
      </div>
      <div class="user-chart" ref="userChart"></div>

    </el-card>
  </div>
</template>

<script>
// import importImg from '@/components/importImg'
import echarts from '@/lib/echarts'
import { getDashboad, getDashboardChart } from '@/services/store.js'
import loading from '@/utils/loading.json'

// import moment from 'moment'
let lineChart1 = null
let lineChart2 = null
let lineChart3 = null
let lineChart4 = null

let pieChart = null

export default {
  name: 'CombineDashboard',
  data () {
    return {
      dateValue: '',
      dateList: [],
      basicInfo: {},
      chartInfo: {}
    }
  },
  components: {
    // importImg
  },
  created () {
    this.$store.state.app.loading = this.$loading(loading)

    this.formatMonth()
    getDashboad().then((res) => {
      this.basicInfo = res.data
    }).catch(() => {
      this.$store.state.app.loading.close()
    })
    this.getChart(this.dateList[0].value, () => {
      this.$nextTick(() => {
        this.init()
        this.initUserChart()
        this.$store.state.app.loading.close()
      })
    })
  },
  methods: {
    init () {
      lineChart1 = echarts.init(this.$refs.test1)
      lineChart2 = echarts.init(this.$refs.test2)
      lineChart3 = echarts.init(this.$refs.test3)
      lineChart4 = echarts.init(this.$refs.test4)
      this.freshLineChart()
    },
    freshLineChart () {
      const month_revenues = []
      const month_orders_count = []
      const month_ticket_revenues = []
      const month_ticket_orders_count = []
      const date_list = []
      this.chartInfo.month_revenues && this.chartInfo.month_revenues.forEach((item) => {
        month_revenues.push(item.value)
        date_list.push(item.date)
      })
      this.chartInfo.month_orders_count && this.chartInfo.month_orders_count.forEach((item) => {
        month_orders_count.push(item.value)
      })
      this.chartInfo.month_ticket_revenues && this.chartInfo.month_ticket_revenues.forEach((item) => {
        month_ticket_revenues.push(item.value)
      })
      this.chartInfo.month_ticket_orders_count && this.chartInfo.month_ticket_orders_count.forEach((item) => {
        month_ticket_orders_count.push(item.value)
      })
      const options = {
        tooltip: {
          trigger: 'axis',
          position: function (pt) {
            return [pt[0], '10%']
          },
          formatter: (params) => {
            console.log(params)
            for (const x in params) {
              return params[x].axisValueLabel + '<br>' + '$' + this.moneyFormat(params[x].value)
            }
          }
        },
        legend: {
          show: false,
          top: '5%',
          left: 'center',
          data: [this.$t('dashboard.turnoverChart'), this.$t('dashboard.orderChart')]
        },
        grid: {
          left: '3%',
          right: '3%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: date_list
        },
        yAxis: [
          {
            name: this.$t('dashboard.turnoverTitle'),
            nameTextStyle: {
              padding: [0, 0, 10, 0]
            },
            splitNumber: 5,
            type: 'value',
            axisLabel: {
              formatter: (value) => {
                const val = this.moneyFormat(value)
                var texts = [val + ' $']
                return texts
              }
            }
          }
        ],
        series: [
          {
            name: this.$t('dashboard.turnoverTitle'),
            data: month_revenues,
            type: 'line',
            smooth: true,
            itemStyle: {
              normal: {
                color: '#fc8a0f',
                lineStyle: {
                  color: '#ff9c35'
                }
              }
            }
          }
        ]
      }
      lineChart1.setOption(options)

      options.yAxis = [
        {
          name: this.$t('dashboard.orderChart'),
          nameTextStyle: {
            padding: [0, 0, 10, 50]
          },
          splitNumber: 5,
          type: 'value',
          axisLabel: {
            formatter: '{value} ' + this.$t('dashboard.dan')
          }
        }
      ]
      options.series = [
        {
          name: this.$t('dashboard.orderChart'),
          data: month_orders_count,
          type: 'line',
          smooth: true,
          itemStyle: {
            normal: {
              color: '#409EFF',
              lineStyle: {
                color: '#409EFF'
              }
            }
          }
        }
      ]
      options.tooltip.formatter = (params) => {
        for (const x in params) {
          return params[x].axisValueLabel + '<br>' + params[x].value + this.$t('dashboard.dan')
        }
      }
      lineChart2.setOption(options)

      options.yAxis = [
        {
          name: this.$t('dashboard.orderTitle'),
          nameTextStyle: {
            padding: [0, 0, 10, 30]
          },
          splitNumber: 5,
          type: 'value',
          axisLabel: {
            formatter: (value) => {
              const val = this.moneyFormat(value)
              var texts = [val + ' $']
              return texts
            }
          }
        }
      ]
      options.series = [
        {
          name: this.$t('dashboard.orderTitle'),
          data: month_ticket_revenues,
          type: 'line',
          smooth: true,
          itemStyle: {
            normal: {
              color: '#fc8a0f',
              lineStyle: {
                color: '#ff9c35'
              }
            }
          }
        }
      ]
      options.tooltip.formatter = (params) => {
        for (const x in params) {
          return params[x].axisValueLabel + '<br>' + '$' + this.moneyFormat(params[x].value)
        }
      }
      lineChart3.setOption(options)

      options.yAxis = [
        {
          name: this.$t('dashboard.orderChart2'),
          nameTextStyle: {
            padding: [0, 0, 10, 100]
          },
          splitNumber: 5,
          type: 'value',
          axisLabel: {
            formatter: '{value} ' + this.$t('dashboard.dan')
          }
        }
      ]
      options.series = [
        {
          name: this.$t('dashboard.orderChart2'),
          data: month_ticket_orders_count,
          type: 'line',
          smooth: true,
          itemStyle: {
            normal: {
              color: '#409EFF',
              lineStyle: {
                color: '#409EFF'
              }
            }
          }
        }
      ]
      options.tooltip.formatter = (params) => {
        console.log(params)
        for (const x in params) {
          return params[x].axisValueLabel + '<br>' + params[x].value + this.$t('dashboard.dan')
        }
      }
      lineChart4.setOption(options)
    },
    initUserChart () {
      pieChart = echarts.init(this.$refs.userChart)
      this.freshPieChart()
    },
    freshPieChart () {
      const user_info = {
        total_users_count: {
          value: this.chartInfo.total_users_count,
          name: this.$t('dashboard.allUser')
        },
        total_existing_users_count: {
          value: this.chartInfo.total_existing_users_count,
          name: this.$t('dashboard.oldUser')
        },
        total_new_users_count: {
          value: this.chartInfo.total_new_users_count,
          name: this.$t('dashboard.newUser')
        }
      }
      const option = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: '60%',
          top: 'center'
        },
        series: [
          {
            name: 'Count',
            type: 'pie',
            radius: ['50%', '70%'],
            center: ['40%', '50%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '36',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: user_info.total_existing_users_count.value, name: user_info.total_existing_users_count.name },
              { value: user_info.total_new_users_count.value, name: user_info.total_new_users_count.name }
              // { value: user_info.total_users_count.value, name: user_info.total_users_count.name }
            ]
          }
        ]
      }
      pieChart.setOption(option)
    },
    formatMonth () {
      var d = new Date()
      var result = [
        {
          text: this.$t('dashboard.thisMonth'),
          value: d.getFullYear() + '-' + (d.getMonth() + 1) + '-01'
        }
      ]
      this.dateValue = result[0].value
      for (var i = 0; i < 12; i++) {
        d.setMonth(d.getMonth() - 1)
        var m = d.getMonth() + 1
        m = m < 10 ? '0' + m : m
        const obj = {
          text: d.getFullYear() + '-' + m,
          value: d.getFullYear() + '-' + m + '-01'
        }
        result.push(obj)
      }
      this.dateList = result
    },
    moneyFormat (num) {
      if (num) {
        return (num / 100).toFixed(2)
      }
      return 0
    },
    formatPercent (num, all) {
      if (all) {
        const d = num / all
        if (d < 1) {
          return Number((d * 100).toFixed(0))
        } else {
          return 100
        }
      }
      return 0
    },
    changeMonth (value) {
      this.$store.state.app.loading = this.$loading(loading)
      this.getChart(value, () => {
        this.freshLineChart()
        this.freshPieChart()
        this.$store.state.app.loading.close()
      })
    },
    getLaDate (value) {
      const index = this.dateList.findIndex((item) => {
        return item.value === value
      })
      if (index > -1) {
        return this.dateList[index].text
      }
    },
    getChart (date, callback) {
      const params = {
        month: date
      }
      getDashboardChart(params).then((res) => {
        this.chartInfo = res.data
        if (callback) callback()
      }).catch(() => {
        this.$store.state.app.loading.close()
      })
    },
    toOrder (routerName, scope) {
      this.$router.push({
        name: routerName,
        query: {
          scope
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "index";
</style>
