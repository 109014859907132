<template>
  <div class="fooyo-dashboard">
    <el-card class="mb20">
      <el-row slot="header">
        <el-col :span="24" class="bolder">
         {{ $t('fooyoDashboard.accountInfo') }}
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :xs="12" :sm="12" :md="12" :lg="6">
          <div class="account-info_item flex">
            <span>{{ $t('fooyoDashboard.storeName') }}</span>
            <span>{{clinetInfo.name_str}}</span>
          </div>
        </el-col>
        <el-col :xs="12" :sm="12" :md="12" :lg="6">
          <div class="account-info_item flex">
            <span>{{ $t('fooyoDashboard.licenceId') }}</span>
            <span>{{clinetInfo.appid}}</span>
          </div>
        </el-col>
        <el-col :xs="12" :sm="12" :md="12" :lg="6">
          <div class="account-info_item flex">
            <span>{{ $t('fooyoDashboard.entityName') }}</span>
            <span>{{clinetInfo.company_name}}</span>
          </div>
        </el-col>
        <el-col :xs="12" :sm="12" :md="12" :lg="6">
          <div class="account-info_item flex">
            <span>{{ $t('fooyoDashboard.storeWebsite') }}</span>
            <span>{{clinetInfo.web_id}}</span>
          </div>
        </el-col>
      </el-row>
    </el-card>
    <el-card class="mb20">
      <el-row slot="header" class="clearfix">
        <el-col :span="6" class="bolder">{{ $t('fooyoDashboard.operationReport') }}</el-col>
        <el-col :span="18" class="flex-end">
          <div class="search-item">
            <span class="label">{{ $t('fooyoDashboard.shops') }}</span>
            <el-select v-model="operationShopValue" :placeholder="$t('placeholder.defaultSelect')" @change="operationOptionChange">
              <el-option v-for="item in shopsOptions" :key="item.id" :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="search-item">
            <span class="label">{{ $t('fooyoDashboard.dateRange') }}</span>
            <el-date-picker v-model="operationDate" type="daterange" range-separator="To" value-format="yyyy-MM-dd"
              :start-placeholder="$t('form.common.startDate')" :end-placeholder="$t('form.common.endDate')" @change="operationOptionChange">
            </el-date-picker>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <div class="today-block">
            <div class="name">{{ $t('fooyoDashboard.newUserOrders') }}</div>
            <el-row class="content">
              <el-col :span="7">{{operationInfo.shop_name || ' '}}</el-col>
              <el-col :span="17" v-if="operationInfo.deliveries">
                <el-row class="mb10" v-for="(type, key) in operationInfo.deliveries" :key="key">
                  <el-col :span="8">{{type.name}}</el-col>
                  <el-col :span="8"><b>{{$t('fooyoDashboard.howOrders', {count: Utils.numberFormat(type.number)})}}</b></el-col>
                  <el-col :span="8" class="text-right"><b class="bule">${{Utils.moneyFormat(type.price)}}</b></el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="today-block">
            <div class="name">{{ $t('fooyoDashboard.newUser') }}</div>
            <el-row type="flex" justify="space-between" class="content">
              <el-col :span="12">{{ $t('fooyoDashboard.newUser') }}</el-col>
              <el-col :span="12" class="text-right"> <b>{{Utils.numberFormat(operationInfo.new_user_count)}}</b> </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <div class="today-block">
            <div class="name">{{ $t('fooyoDashboard.voucherRedemption') }}</div>
            <el-row type="flex" justify="space-between" class="content">
              <el-col :span="12">{{$t('fooyoDashboard.voucherIssued')}}</el-col>
              <el-col :span="12" class="text-right"><b>{{Utils.numberFormat(operationInfo.new_coupon_count)}}</b></el-col>
            </el-row>
            <el-row type="flex" justify="space-between" class="content">
              <el-col :span="12">{{$t('fooyoDashboard.voucherRedeemed')}}</el-col>
              <el-col :span="12" class="text-right"><b>{{Utils.numberFormat(operationInfo.used_coupon_count)}}</b></el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </el-card>
    <el-card class="mb20">
      <el-row slot="header" class="clearfix">
        <el-col :span="6" class="bolder">{{ $t('fooyoDashboard.orderFulfillment') }}</el-col>
        <el-col :span="18" class="flex-end">
          <div class="search-item">
            <span class="label">{{ $t('fooyoDashboard.shops') }}</span>
            <el-select v-model="orderShopValue" :placeholder="$t('placeholder.defaultSelect')" @change="orderOptionChange">
              <el-option v-for="item in shopsOptions" :key="item.id" :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="search-item">
            <span class="label">{{ $t('fooyoDashboard.dateRange') }}</span>
            <el-date-picker v-model="orderFulfillmentDate" type="daterange" range-separator="To" value-format="yyyy-MM-dd"
              :start-placeholder="$t('form.common.startDate')" :end-placeholder="$t('form.common.endDate')" @change="orderOptionChange">
            </el-date-picker>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20" class="mb20">
        <el-col :span="12">
          <div class="today-block">
            <div class="name">{{ $t('fooyoDashboard.ordersFulfilled') }}</div>
            <el-row class="content">
              <el-col :span="7">{{orderfulfillmentInfo.shop_name}}</el-col>
              <el-col :span="17">
                <el-row class="mb10" v-for="(type, key) in orderfulfillmentInfo.deliveries" :key="key">
                  <el-col :span="8">{{type.name}}</el-col>
                  <el-col :span="8"><b>{{$t('fooyoDashboard.howOrders', {count: Utils.numberFormat(type.number)})}}</b></el-col>
                  <el-col :span="8" class="text-right"><b class="bule">${{Utils.moneyFormat(type.price)}}</b></el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
      <div class="mb20 echarts">
        <span class="bolder absolute">{{ $t('fooyoDashboard.salesAndOrdersFulfilled') }}</span>
        <div ref="line1" class="echarts"></div>
      </div>
      <div class="mb20 echarts">
        <span class="bolder absolute l4">{{ $t('fooyoDashboard.averageBasketSize') }}</span>
        <div ref="line2" class="echarts"></div>
      </div>
      <el-row style="padding: 0px 15px 15px 0px;">
        <el-row class="mb20 mr10">
          <el-col :span="6" class="bolder">{{ $t('fooyoDashboard.topSellingProducts') }}</el-col>
          <el-col :span="18" class="flex-end">
            <div class="search-item">
              <el-select v-model="goodsTypeSortValue" placeholder="$t('placeholder.defaultSelect')" @change="goodsTypeSortChange">
                <el-option v-for="item in goodsTypesOptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </el-col>
        </el-row>
        <div class="mb15 fz13 ml10 mr10" v-for="(goods, index) in hotGoodsInfo" :key="index">
          <div class="flex-between pr5 mb8" >
            <b>{{goods.name}}</b>
            <span>{{goodsTypeSortValue === 'sales' ? goods.number : '$' + Utils.moneyFormat(goods.price)}}</span>
          </div>
          <el-progress :percentage="formatPercent(goods)" :show-text="false"></el-progress>
          <!-- <div class="separator"></div> -->
        </div>
      </el-row>
    </el-card>
    <el-card>
      <el-row slot="header" class="clearfix">
        <el-col class="bolder">{{ $t('fooyoDashboard.userActivity') }}({{activityUserDate}})</el-col>
      </el-row>
      <div ref="pie" class="echarts"></div>
    </el-card>
  </div>
</template>

<script>
import echarts from '@/lib/echarts'
import Utils from '@/utils/Utils'
import moment from 'moment'
import loading from '@/utils/loading.json'
import { getFooyoDashboardOperationalData, getFooyoDashboardOdersData, getFooyoDashboardUserData } from '@/services/store.js'
import { getShopsSimpleList } from '@/services/shops.js'
import { getClientsEditDetails } from '@/services/setting'
let lineChart1 = null
let lineChart2 = null
let pieChart = null

export default {
  name: 'fooyoDashboard',
  data () {
    return {
      Utils,
      shopsOptions: [],
      operationShopValue: 'all',
      operationDate: [],
      operationInfo: {},
      orderfulfillmentInfo: {},
      activityUserInfo: {},
      goodsTypesOptions: [{
        value: 'sales',
        label: this.$t('fooyoDashboard.bySales')
      }, {
        value: 'amount',
        label: this.$t('fooyoDashboard.byAmount')
      }],
      orderShopValue: 'all',
      goodsTypeSortValue: 'sales',
      hotGoodsInfo: [],
      orderFulfillmentDate: [],
      activityUserDate: '',
      numberTotal: 0,
      priceTotal: 0,
      clinetInfo: {
        company_name:'',
        web_id: '',
        name_str: '',
        appid: ''
      }
    }
  },
  components: {
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.$store.state.app.loading = this.$loading(loading);
      const dateRange = this.defaultDateRange();
      const requset1 = getShopsSimpleList().then(res => {
        const defaultItem = { id: 'all', name: this.$t('fooyoDashboard.allShops') }
        res.data.shops.unshift(defaultItem)
        this.shopsOptions = res.data.shops
      })
      const initeRequest2 = new Promise(resolve => {
        this.getOperationData()
        return resolve()
      })
      const initeRequest3 = new Promise(resolve => {
        this.getActivityUserData(dateRange)
        return resolve()
      })
      const initeRequest4 = new Promise(resolve => {
        this.getChartData(() => {
          this.$nextTick(() => {
            this.initLineChart()
            return resolve()
          })
        })
      })
      Promise.all([requset1, initeRequest2, initeRequest3, initeRequest4]).then(res => {
        this.$store.state.app.loading.close()
      })
      this.initAccountInfo()
    },
    async initAccountInfo () {
      let user = localStorage.getItem('user');
      if(user) {
        const userObj = JSON.parse(user);
        try {
          const {data} = await getClientsEditDetails(userObj.client_id);
          if(data && data.client) {
            data.client.name_str = this.$i18n.locale === 'en' ? data.client.name_en : data.client.name;
            this.clinetInfo = data.client;
          }
        } catch (error) {
          
        }
      }
      
    },
    initLineChart () {
      lineChart1 = echarts.init(this.$refs.line1)
      lineChart2 = echarts.init(this.$refs.line2)
      this.freshLineChart()
    },
    initPieChart () {
      pieChart = echarts.init(this.$refs.pie)
      this.freshPieChart()
    },
    async getChartData (callback) {
      const params_f = {
        shop_id: this.orderShopValue,
        date_between: this.orderFulfillmentDate.join('to')
      }
      if (this.orderShopValue === 'all') delete params_f.shop_id
      getFooyoDashboardOdersData(params_f).then(res => {
        res.data.hot_items_sort_by_number.forEach(item => {
          this.numberTotal += item.number
        })
        res.data.hot_items_sort_by_price.forEach(item => {
          this.priceTotal += item.price
        })
        this.orderfulfillmentInfo = res.data
        this.hotGoodsInfo = this.goodsTypeSortValue === 'sales' ? res.data.hot_items_sort_by_number : res.data.hot_items_sort_by_price
        if (callback) callback()
      })
    },
    async getOperationData (callback) {
      const params_f = {
        shop_id: this.operationShopValue,
        date_between: this.operationDate.join('to')
      }
      if (this.operationShopValue === 'all') delete params_f.shop_id
      getFooyoDashboardOperationalData(params_f).then(res => {
        this.operationInfo = res.data
        if (callback) callback()
      })
    },
    async getActivityUserData (dateRange) {
      getFooyoDashboardUserData({
        date_between: dateRange
      }).then(res => {
        this.activityUserInfo = res.data
        this.$nextTick(() => {
          this.initPieChart()
        })
      })
    },
    freshLineChart () {
      const month_average_per_orders = []
      const month_sales_amount = []
      const month_sales_orders = []
      const date_list = []
      this.orderfulfillmentInfo.average_number_per_order && this.orderfulfillmentInfo.average_number_per_order.forEach((item) => {
        month_average_per_orders.push(item.number)
        date_list.push(item.date)
      })
      this.orderfulfillmentInfo.sales_and_completed_orders && this.orderfulfillmentInfo.sales_and_completed_orders.forEach((item) => {
        month_sales_amount.push((item.price / 100).toFixed(2))
        month_sales_orders.push(item.number)
      })
      const maxY1 = this.getMaxNumber(month_sales_amount)
      const maxY2 = this.getMaxNumber(month_sales_orders)
      const option = {
        tooltip: {
          trigger: 'axis',
          formatter: (params) => {
            let str = `<span style="font-size: 12px; color: #999999"><span style="padding-right: 10px">${params[0].axisValueLabel}</span>${this.orderfulfillmentInfo.shop_name}</span><br/> `
            params.forEach(item => {
              str = str + `<b style="line-height: 15px; height: 15px; min-width: 200px; display: flex; justify-content: space-between; margin-top: -10px;"> <p>${item.seriesName}:</p> <p style="color:#409eff;">${item.componentIndex === 0 ? '$' : ''}${item.value}</p> </b><br/>`
            })
            return str
          }
          // formatter: '<span style="font-size: 12px; color: #999999"><span style="padding-right: 10px">{b0}</span>' + this.orderfulfillmentInfo.shop_name + '</span><br/> ' +
          // '<b style="line-height: 15px; height: 15px; min-width: 200px; display: flex; justify-content: space-between; margin-top: -10px;"> <p>{a0}:</p> <p style="color:#409eff;">' + '$' + '{c0}</p> </b><br/>' +
          // '<b style="line-height: 15px; height: 15px; min-width: 200px; display: flex; justify-content: space-between; margin-top: -10px;"> <p>{a1}:</p> <p>{c1}</p> </b><br/>'
        },
        grid: {
          left: '50px',
          right: '50px',
          top: '80px',
          bottom: '0px',
          containLabel: true
        },
        legend: {
          left: '200px',
          data: [this.$t('fooyoDashboard.sales'), this.$t('fooyoDashboard.orderFulfilled')]
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: date_list,
          nameTextStyle: {
            color: '#303133',
            fontWeight: '500',
            fontSize: '14'
          }
        },
        yAxis: [
          {
            name: this.$t('fooyoDashboard.sales') + '/$',
            type: 'value',
            inverse: false,
            max: maxY1,
            min: 0,
            interval: Math.ceil(maxY1 / 5),
            splitNumber: 5,
            axisLabel: {
              formatter: (value) => {
                value = value.toFixed(2)
                return Utils.numberFormat(value)
              }
            }
          },
          {
            name: this.$t('fooyoDashboard.orderFulfilled'),
            nameLocation: 'end',
            alignTicks: true,
            type: 'value',
            inverse: false,
            max: maxY2,
            min: 0,
            interval: Math.ceil(maxY2 / 5),
            splitNumber: 5
          }
        ],
        series: [{
          name: this.$t('fooyoDashboard.sales'),
          type: 'line',
          yAxisIndex: 0,
          showSymbol: false,
          itemStyle: {
            normal: {
              color: '#f3983a'
            }
          },
          data: month_sales_amount
        }, {
          name: this.$t('fooyoDashboard.orderFulfilled'),
          type: 'line',
          showSymbol: false,
          yAxisIndex: 1,
          itemStyle: {
            normal: {
              color: '#409eff'
            }
          },
          data: month_sales_orders
        }]
      }
      lineChart1.setOption(option)

      const maxY3 = this.getMaxNumber(month_average_per_orders)
      option.grid.left = '70px'
      option.grid.right = '70px'
      option.tooltip.formatter =  '<span style="font-size: 12px; color: #999999"><span style="padding-right: 10px">{b0}</span>' + this.orderfulfillmentInfo.shop_name + '</span><br/><b><span style="padding-right: 30px;">{a0}:</span> {c0}</b>'
      option.legend.left = '170px'
      option.legend.data = [this.$t('fooyoDashboard.averageBasketSize')]
      option.yAxis = {
        name: this.$t('fooyoDashboard.averageBasketSize'),
        max: maxY3,
        min: 0,
        splitNumber: 5
      }
      option.series = [
        {
          name: this.$t('fooyoDashboard.averageBasketSize'),
          data: month_average_per_orders,
          type: 'line',
          smooth: false,
          showSymbol: false,
          itemStyle: {
            normal: {
              color: '#66ab41'
            }
          }
        }
      ]

      lineChart2.setOption(option)
    },
    freshPieChart () {
      var pieChartData = [
        { value: this.activityUserInfo.active_users_count, name: this.$t('fooyoDashboard.activeUser') },
        { value: this.activityUserInfo.inactive_users_count, name: this.$t('fooyoDashboard.inactiveUser') }
      ]
      const option = {
        tooltip: {
          trigger: 'item'
        },
        grid: {
          left: '200px',
          containLabel: true
        },
        legend: {
          orient: 'vertical',
          left: '380px',
          top: 'center',
          formatter: (name) => {
            let value = 0
            for (let i = 0; i < pieChartData.length; i++) {
              if (pieChartData[i].name === name) {
                value = pieChartData[i].value
              }
            }
            const arr = [
              '{a|' + name + '}',
              '{b|' + value + '}'
            ]
            return arr.join('')
          },
          textStyle: {
            width: 210,
            rich: {
              a: {
                fontSize: 14,
                fontWeight: 500
              },
              b: {
                fontSize: 14,
                fontWeight: 500,
                align: 'right',
                lineHeight: 25
              }
            }
          }
        },
        series: [
          {
            type: 'pie',
            center: ['200px', '50%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: false,
                fontSize: '36',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: pieChartData,
            color: ['#5470c6', '#e4e4e4']
          }
        ]
      }
      pieChart.setOption(option)
    },
    getMaxNumber (maxs) {
      let maxNumber = (Math.max(...maxs)) * 1.1
      const times = maxNumber >= 5 ? 5 : 10
      maxNumber = (maxNumber + (times - maxNumber % times)) * 100 / 100
      return maxNumber
    },
    defaultDateRange () {
      const daysInMonth = (moment().daysInMonth()) - 1
      const endDay = moment().format('YYYY-MM-DD')
      const startDay = moment().subtract(daysInMonth, 'days').format('YYYY-MM-DD')
      const activeStartDay = moment().subtract(6, 'months').format('YYYY-MM-DD')
      this.operationDate = [startDay, endDay]
      this.orderFulfillmentDate = [startDay, endDay]
      this.activityUserDate = activeStartDay + '~' + endDay
      return activeStartDay + 'to' + endDay;
    },
    goodsTypeSortChange (value) {
      if (value === 'sales') {
        this.hotGoodsInfo = this.orderfulfillmentInfo.hot_items_sort_by_number
      } else {
        this.hotGoodsInfo = this.orderfulfillmentInfo.hot_items_sort_by_price
      }
    },
    operationOptionChange () {
      this.$store.state.app.loading = this.$loading(loading)
      this.getOperationData(() => {
        this.$store.state.app.loading.close()
      })
    },
    orderOptionChange () {
      this.$store.state.app.loading = this.$loading(loading)
      this.getChartData(() => {
        this.$nextTick(() => {
          this.freshLineChart()
          this.$store.state.app.loading.close()
        })
      })
    },

    formatPercent (data) {
      const num = this.goodsTypeSortValue === 'sales' ? data.number : data.price
      const all = this.goodsTypeSortValue === 'sales' ? this.numberTotal : this.priceTotal
      if (all) {
        const d = num / all
        if (d < 1) {
          return Number((d * 100).toFixed(0))
        } else {
          return 100
        }
      }
      return 0
    }
  }
}
</script>

<style lang="scss">
@import "index";
</style>
