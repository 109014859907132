// const echarts = equire([
//   'line',
//   'tooltip'
// ])

// export default echarts
// 加载echarts，注意引入文件的路径
import * as echarts from 'echarts/lib/echarts'

import { LineChart, PieChart } from 'echarts/charts'
import { GridComponent, LegendComponent, TooltipComponent } from 'echarts/components'
echarts.use([GridComponent, LegendComponent, TooltipComponent, PieChart, LineChart])
// 再引入你需要使用的图表类型，标题，提示信息等
// import 'echarts/lib/chart/LineChart'
// import 'echarts/lib/component/legend'
// import 'echarts/lib/component/title'
// import 'echarts/lib/component/xAxis'

export default echarts
